import type { BookingRequestPriceQueryParameters, BookingRequestPriceResponse, RequestInfoPricesQueryParameters } from '@yescapa-dev/ysc-api-js/legacy'
import { BOOKING_STATES } from '@yescapa-dev/ysc-api-js/modern'
import { format, sub } from 'date-fns'
import { MINIMUM_PRICE_FOR_CANCEL_INSURANCE } from '~/constants/bookings'
import type { BookingsPrices } from '~/types/bookingRequest'
import { YSC_API_BOOKING_GUEST_ERROR, YSC_API_CAMPER_PRICE_ERROR } from '~/utils/error/YscErrorClasses'

export const useBookingRequestStore = defineStore('bookingRequest', () => {
  const { $api } = useYscApi()
  const bookingGuestStore = useBookingsGuestStore()
  const camperStore = useCamperStore()
  const userStore = useUserStore()

  const prices = ref<BookingRequestPriceResponse | BookingsPrices>()
  const { withErrorManagerHandling } = useWithErrorManagerHandling()

  const refreshBookingRequestPrices = async ({
    params,
    rethrow = false,
  }: {
    params?: Partial<BookingRequestPriceQueryParameters>
    rethrow?: boolean
  } = {}) => {
    if (!isDefined(camperStore.camper)) {
      return
    }

    if (!isDefined(bookingGuestStore.bookingGuest) || bookingGuestStore.bookingGuest.state === BOOKING_STATES.ABANDONED) {
      const form = {
        date_from: params?.date_from ?? bookingGuestStore.bookingGuest?.date_from,
        date_to: params?.date_to ?? bookingGuestStore.bookingGuest?.date_to,
        hour_from: params?.hour_from ?? bookingGuestStore.bookingGuest?.hour_from,
        hour_to: params?.hour_to ?? bookingGuestStore.bookingGuest?.hour_to,
        kilometers: params?.kilometers ?? bookingGuestStore.bookingGuest?.km_option,
        insurance: params?.insurance ?? bookingGuestStore.bookingGuest?.insurance.id,
        borned_on: useSafeISODate(userStore.user?.borned_on) ?? format(sub(new Date(), { years: 23, days: 1 }), 'yyyy-MM-dd'),
        second_driver: params?.second_driver ?? bookingGuestStore.bookingGuest?.second_driver,
        ad_option: params?.ad_option ?? bookingGuestStore.bookingGuest?.options_ids.join(','),
        code: params?.code,
        request_step: params?.request_step ?? undefined,
      }

      try {
        assertAllNullableTypeIsType(form, 'date_from', 'date_to', 'kilometers')
      }
      catch (_e) {
        return
      }

      const idCamper = camperStore.camper.id
      prices.value = await withErrorManagerHandling(
        () => $api.products.getBookingRequestPrices(idCamper, form),
        YSC_API_CAMPER_PRICE_ERROR,
        rethrow,
      )
      return
    }

    const idBooking = bookingGuestStore.bookingGuest.id
    prices.value = await withErrorManagerHandling(
      () => $api.requests.getPrices(idBooking, {}),
      YSC_API_BOOKING_GUEST_ERROR,
      rethrow,
    )
  }

  const updateBookingRequestPrices = async ({
    params,
    rethrow = false,
  }: {
    params: RequestInfoPricesQueryParameters
    rethrow?: boolean
  }) => {
    if (!isDefined(bookingGuestStore.bookingGuest)) {
      return
    }
    const idBooking = bookingGuestStore.bookingGuest.id
    prices.value = await withErrorManagerHandling(
      () => $api.requests.patchPrices(idBooking, params),
      YSC_API_BOOKING_GUEST_ERROR,
      rethrow,
    )
  }

  const bookingGuestIsElligibleToCancelInsurance = computed(() => {
    return Boolean(bookingGuestStore.bookingGuest?.permissions.can_subscribe_cancel_insurance)
      && isDefined(prices.value?.price.value)
      && prices.value.price.value > MINIMUM_PRICE_FOR_CANCEL_INSURANCE
  })

  return {
    prices,
    bookingGuestIsElligibleToCancelInsurance,
    updateBookingRequestPrices,
    refreshBookingRequestPrices,
  }
})
